
export enum APIRequestResources {
  AuthService = 'auth',
  EmployeeService ='employee',
  CategoryService = 'employee-category',
  DepartmentService ='department',
  ManagerService = 'gatepassManager',
  GatepassService = 'gatepass',
  EmployeeTypeService = 'employee-type',
  GatepasstypeService = 'gate-pass-type',

}
