import {Component, effect, inject, signal} from '@angular/core';
import {CreateManagerComponent} from "../../managarDashboard";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {ModalService} from "../../shared/services/modal.service";
import {Chart} from "chart.js/auto";
import {GatepassDTO,} from "../interfaces/gatepass.entity";
import {FormsModule} from "@angular/forms";
import {GatepassService} from "../../../shared/services/gatepass.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-gatepass-grid',
  standalone: true,
  imports: [
    CreateManagerComponent,
    PaginationComponent,
    FormsModule,
    NgClass
  ],
  templateUrl: './gatepass-grid.component.html',
  styleUrl: './gatepass-grid.component.scss'
})
export class GatepassGridComponent {
  ngAfterViewInit() {
    this.createChart();
  }

  modal=inject(ModalService)
  fetchGatepassDto: GatepassDTO[]=[]
  gatepassService=inject(GatepassService)
  gatepassId=signal<number>(-1);

  totalItems = 0;
  itemsPerPage = 10;
  onPageChange(pageNumber: number) {
  }

  searchParams={
    employee_id:'',
    gate_pass_type_id:'',
    status:'',
    page_number: 1,
    items_per_page: 10
  }

  creategatepassDTO = {
    employeeId: 0,
    gatepassTypeId: 0,
    purpose: '',
    requestedDate: '',
    status:'',
    startTime:'',
    endTime:'',
    verifiedBy:'',
    noOfMinutes:0,
    employee:'',
    gatePasssType:''
  }

  constructor() {
    this.fetchData()

    effect(()=>{
      const gatepass=this.gatepassService.active();
      if(gatepass){
        this.gatepassId.set(gatepass.id);
        this.creategatepassDTO=gatepass
      }
    },{allowSignalWrites:true});
  }

  onClick(id: number) {
    this.gatepassService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching gatepasses:', error);
      }
    );
  }

  fetchData() {
    this.gatepassService.find(this.searchParams, true).subscribe({
      next: (response) => {
        console.log('API Response:', response);
        this.fetchGatepassDto = response.data ?? [];
      },

      error: (err: any) => {
        console.error('Error fetching data:', err);
        this.fetchGatepassDto = [];
      }
    });
  }

  openModal() {
    this.modal.$$ManagerModal.set(true)
  }

  public chart: any;
  createChart() {
    this.chart = new Chart('pieChart', {
      type: 'doughnut',
      data: {
        labels: ['Personal', 'Official'],
        datasets: [{
          data: [100, 25],
          backgroundColor: [
            '#9a7f87',
            '#9bc2dc',

          ],
          hoverBackgroundColor: [
            '#d691a0',
            '#4d9acf',

          ]
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'GATEPASS'
          }
        }
      }
    });
  }

}
