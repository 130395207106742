<div class="main">
  <div class="overflow-x-auto">
      <div class="flex flex-wrap items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4 sm:mb-0 w-full sm:w-auto">
        <div class="relative w-full sm:w-auto">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 mb-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="text" id="table-search" class="block w-full sm:w-64 pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 mb-5"
                 placeholder="Search Employee NO" [(ngModel)]="searchParams.employee_id">
        </div>        <div class="w-full sm:w-auto">
        <select id="type" [(ngModel)]="searchParams.gate_pass_type_id" class="selectField w-full sm:w-auto mb-5" required>
          <option value="" hidden="" disabled>Select Status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="pending">Pending</option>
          <option value="deleted">Deleted</option>
        </select>
      </div>
        <div class="w-full sm:w-auto">
          <select id="status" [(ngModel)]="searchParams.status" class="selectField w-full sm:w-auto mb-5" required>
            <option value="" hidden="" disabled>Select Type</option>
            <option value="active">Personal</option>
            <option value="inactive">Official</option>
          </select>
        </div>
        <div class="w-full sm:w-auto">
          <button type="button" (click)="fetchData()" class="button-blue w-full sm:w-auto mb-5">
            Search
          </button>
        </div>

      <table class="table">
        <thead class="table-thead">
        <tr>
          <th scope="col" class="px-6 py-3">Employee No</th>
          <th scope="col" class="px-6 py-3">Employee</th>
          <th scope="col" class="px-6 py-3">Gate Pass Type</th>
          <th scope="col" class="px-6 py-3">Purpose</th>
          <th scope="col" class="px-6 py-3">Date</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Time-out</th>
          <th scope="col" class="px-6 py-3">Time-in</th>
          <th scope="col" class="px-6 py-3">Verified By</th>
          <th scope="col" class="px-6 py-3">NO of Minutes</th>
        </tr>
        </thead>
        <tbody>

          @for(gatepass of fetchGatepassDto ; track gatepass){
            <tr class="bg-white border-b" (click)="onClick(gatepass.id)">
              <td class="px-6 py-4">{{ gatepass.employeeId }}</td>
              <td class="px-6 py-4">{{ gatepass.gatepassTypeId }}</td>
              <td class="px-6 py-4">{{ gatepass.purpose }}</td>
              <td class="px-6 py-4">{{ gatepass.requestedDate }}</td>
              <td class="px-6 py-4"><span class="status-badge"
             [ngClass]="gatepass.status.toLowerCase()">{{ gatepass.status }}</span></td>
              <td class="px-6 py-4">
                <svg >" width="20" height="20" class="cursor-pointer" viewBox="0 0 20 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 14H10V10H9M10 6H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282
                      17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626
                      4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821
                      5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                    stroke="#1C64F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
      <div class ="" (click)="ngAfterViewInit() "></div>
      <div class=" main max-w-full border-4 justify-items-start">
      <div class="chart-container ">
    <canvas id="pieChart"></canvas>
  </div>
</div>
    </div>

  <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                  (pageChanged)="onPageChange($event)"></app-pagination>
</div>


<app-create-manager></app-create-manager>
