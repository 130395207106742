import {Component, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {CreateEmployeeComponent} from "../create-employee/create-employee.component";
import {EmployeeService} from "../services/employee.service";
import {NgClass} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {DotLoadingServices} from "../../../../core";

@Component({
  selector: 'app-employee-grid',
  standalone: true,
  imports: [
    CreateEmployeeComponent,
    NgClass,
    ReactiveFormsModule,
    FormsModule,
    PaginationComponent
  ],
  templateUrl: './employee-grid.component.html',
  styleUrl: './employee-grid.component.scss'
})
export class EmployeeGridComponent {

  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();

  employeeService = inject(EmployeeService)
  dotLoadingServices = inject(DotLoadingServices)

  searchParams = {
    full_name: '',
    code: '',
    employee_status: '',
    department: -1,
    category: -1,
    items_per_page: 10,
    page_number: 10
  }

  totalItems = 0;
  itemsPerPage = 10;

  onClick(id: number) {
    this.employeeService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching employees:', error);
      }
    );
  }

  search() {
    this.dotLoadingServices.setLoading(true);
    this.employeeService.find(this.searchParams, true).subscribe(
      (response) => {
        this.dotLoadingServices.setLoading(false);
        this.totalItems = response.data.totalItems
        this.itemsPerPage = response.data.itemsPerPage
      },
      (error) => {
        console.error('Error fetching employees:', error);
        this.dotLoadingServices.setLoading(false);
      }
    );
  }

  showCreateEmployee = signal(false);

  onPageChange(pageNumber: number) {
    this.searchParams = {
      ...this.searchParams,
      page_number: pageNumber
    };
    this.search();
  }

  openCreateEmployee() {
    this.showCreateEmployee.set(true);
  }
//update
  onShowModalChange(show: boolean) {
    this.showCreateEmployee.set(show);
  }

  clearSearch() {
    this.searchParams = {
      full_name: '',
      code: '',
      employee_status: '',
      department: -1,
      category: -1,
      items_per_page: 1,
      page_number: 10
    };
    const searchInput = document.getElementById('table-search') as HTMLInputElement;
    if (searchInput) {
      searchInput.value = '';
    }
    const statusSelect = document.getElementById('type') as HTMLSelectElement;
    if (statusSelect) {
      statusSelect.value = '';
    }
    this.search();
  }
}
