import {Component} from '@angular/core';

@Component({
  selector: 'app-create-leave',
  standalone: true,
  imports: [],
  templateUrl: './create-leave.component.html',
  styleUrl: './create-leave.component.scss',
})
export class CreateLeaveComponent {
  isLeaveActive: boolean = true;
  isGatePassActive: boolean = false;

  setActive(button: string): void {
    if (button === 'leave') {
      this.isLeaveActive = true;
      this.isGatePassActive = false;
      console.log('leave');
    } else if (button === 'gatepass') {
      this.isGatePassActive = true;
      this.isLeaveActive = false;
      console.log('gatepass');
    }
  }
}
