import {Component, effect, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {Modal} from "flowbite";
import {take} from "rxjs";
import {APIResponse, DotLoadingServices, NotificationService} from "../../../../core";
import {GatepassDTO} from "../interfaces/gatepass.entity";
import {GatepassTypeDTO} from "../../../interface/gatepasstype.entity";
import {GatepassService} from "../../../shared/services/gatepass.service";
import {GatepasstypeService} from "../../../shared/services/gatepasstype.service";

@Component({
  selector: 'app-gatepass-create',
  standalone: true,
  templateUrl: './gatepass-create.component.html',
  imports: [
    FormsModule
  ],
  styleUrl: './gatepass-create.component.scss'
})

export class GatepassCreateComponent {
  @Input() showModal = signal(true);
  @Output() showModalChange = new EventEmitter<boolean>();
  private modal: Modal | null = null;
  gatepassId = signal<number>(-1);

  gatepassDTO:GatepassDTO[]=[]
  gatepassService = inject(GatepassService);
  gatepassTypeService = inject(GatepasstypeService);
  gatepassTypeData: GatepassTypeDTO[] = []

  creategatepassDTO = {
    employeeId: 0,
    gatepassTypeId: 0,
    purpose: '',
    requestedDate: '',
  }

  constructor(
    private notificationService: NotificationService,
    private dotLoadingServices: DotLoadingServices,
  ) {
      effect(() => {
        const gatepass = this.gatepassService.active();
        if (gatepass) {
          this.gatepassId.set(gatepass.id);
          this.creategatepassDTO=gatepass;
        }
      }, {allowSignalWrites: true});

    this.gatepassTypeService.find(true).subscribe((res) => {})

  }

    createGatepass(){
    const createDto ={
      employeeId:this.creategatepassDTO.employeeId,
      gatepassTypeId:this.creategatepassDTO.gatepassTypeId,
      purpose:this.creategatepassDTO.purpose,
      requestedDate:this.creategatepassDTO.requestedDate,
    };
    this.gatepassService.create(createDto).subscribe({
      next:response => {
        console.log(response);
      }
    })
    }

    submit() {
      this.dotLoadingServices.setLoading(true);
      const isUpdate = this.gatepassId() > 0;
      const action = isUpdate ? 'update' : 'create';
      console.log(this.creategatepassDTO)

      const request$ = isUpdate
        ? this.gatepassService.update(this.gatepassId(), this.creategatepassDTO)
        : this.gatepassService.create(this.creategatepassDTO);
      request$.pipe(take(1)).subscribe({
        next: (response: APIResponse<GatepassDTO>) => {

          const successAction = isUpdate ? 'updated' : 'created';
          this.notificationService.showNotification({
            type: 'success',
            message: `Gatepass ${successAction} successfully`,
          });
          this.dotLoadingServices.setLoading(false);
          this.modal?.hide();
          if (!isUpdate) {
            this.refreshCategoryList();
          }
        },
        error: (error) => {
          let errorMessage = `An unexpected error occurred while ${action}ing the gatepass`
          switch (error.status) {
            case 409:
              errorMessage = error.error.message || `A gatepass with this ${isUpdate ? 'new ' : ''}code or name already exists`;
              break;
            case 404:
              errorMessage = `Gatepass not found. It may have been deleted`;
              break;
            case 400:
              errorMessage = error.error.message || `Invalid gatepass data provided`;
              break;
            case 403:
              errorMessage = `You don't have permission to ${action} gatepasses`;
              break;
          }
          this.notificationService.showNotification({
            type: 'error',
            message: errorMessage,
          });
          console.error(`Error ${action}ing gatepass:`, error);
          this.dotLoadingServices.setLoading(false);
        }
      });
    }
    private refreshCategoryList() {
      this.gatepassService.find({
        employee_id:'',
        gate_pass_type_id:'',
        status:'',
        page_number: 1,
        items_per_page: 10
      }, true).pipe(take(1)).subscribe();
    }
    ngAfterViewInit(): void {
      this.initializeModal();
      this.gatepassService.initial();
    }
    private initializeModal(): void {
      const modalElement = document.getElementById('crud-modal');
      if (modalElement) {
        this.modal = new Modal(modalElement, {
          placement: 'center',
          backdrop: 'static',
          closable: true,
          onHide: () => {
            this.showModal.set(false);
            this.showModalChange.emit(false);
          }
        });
      }
    }

    openModal(): void {
      this.resetForm();
    }
    closeModal(): void {
      this.modal?.hide();
      this.gatepassService.initial();
    }
    validateForm(): boolean {
      return Boolean(this.creategatepassDTO.employeeId && this.creategatepassDTO.gatepassTypeId &&
        this.creategatepassDTO.purpose && this.creategatepassDTO.requestedDate);
    }
    resetForm(): void {
      this.gatepassId.set(-1);
      this.creategatepassDTO = {
        employeeId:0,
        gatepassTypeId:0,
        purpose:'',
        requestedDate:'',

      }
      this.gatepassService.initial();
    }
    toggleDepModal(show: boolean): void {
      this.showModal.set(show);
      this.showModalChange.emit(show);
    }


  protected readonly status = status;
}
