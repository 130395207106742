import {Component, EventEmitter, inject, Input, Output, signal} from '@angular/core';
import {NgClass,  NgIf} from "@angular/common";
import {CategoryDTO} from "../interface/category.entity";
import {CreateCategoryComponent} from "../create-category/create-category.component";
import {FormsModule} from "@angular/forms";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {DotLoadingServices} from "../../../../core";
import {CategoryService} from "../../../shared/services/category.service";

@Component({
  selector: 'app-category-grid',
  standalone: true,
  imports: [ CreateCategoryComponent, FormsModule, NgClass, NgIf, PaginationComponent],
  templateUrl: './category-grid.component.html',
  styleUrl: './category-grid.component.scss'
})
export class CategoryGridComponent{
  category: CategoryDTO[] = []
  @Input() showModal = signal(false);
  @Output() showModalChange = new EventEmitter<boolean>();
  categoryService = inject(CategoryService)
  dotLoadingServices = inject(DotLoadingServices)


  searchParams = {
    code:'',
    status: '',
    page_number: 1,
    items_per_page: 10
  }

  totalItems = 0;
  itemsPerPage = 10;

  updateDTO = {
    code: '',
    categoryName: '',
    status: '',
    level: '',
    createdBy: '',
  };

  showCreateCategory = signal(false);

  constructor() {
    this.onShowModalChange(false);
  }

  onClick(id: number) {
    this.categoryService.getById(id.toString(), true).subscribe(
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  search() {
    this.dotLoadingServices.setLoading(true);
    this.categoryService.find(this.searchParams, true).subscribe(
      (response) => {
        this.totalItems = response.data.totalItems
        this.itemsPerPage = response.data.itemsPerPage
        const cate=response.data.data
        this.category.push(...cate)
        this.dotLoadingServices.setLoading(false);
      },
      (error) => {
        console.error('Error fetching categories:', error);
        this.dotLoadingServices.setLoading(false);
      }
    );
  }

    onPageChange(pageNumber: number) {
      this.searchParams = {
        ...this.searchParams,
        page_number: pageNumber
      };
      this.search();
    }

    openCreateCategory() {
      this.showCreateCategory.set(true);
      this.updateDTO = {
        code: '',
        categoryName: '',
        status: '',
        level: '',
        createdBy: '',
      };
      console.log('Create mode activated:', this.updateDTO);
    }

    openUpdateCategory(){
      this.showCreateCategory.set(true);
      this.updateDTO ={
        code: this.updateDTO.code,
        categoryName: this.updateDTO.categoryName,
        status: this.updateDTO.status,
        level: this.updateDTO.level,
        createdBy: this.updateDTO.createdBy,
      };

    console.log('Update mode for category:', this.updateDTO);
  }
  onShowModalChange(show: boolean) {
    this.showCreateCategory.set(show);
  }
  clearSearch() {
    this.searchParams = {
      code: '',
      status: '',
      page_number: 1,
      items_per_page: 10
    };
    const searchInput = document.getElementById('table-search') as HTMLInputElement;
    if (searchInput) {
      searchInput.value = '';
    }
    const statusSelect = document.getElementById('type') as HTMLSelectElement;
    if (statusSelect) {
      statusSelect.value = '';
    }
    this.search();
  }

}
