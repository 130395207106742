import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, catchError, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {APIRequestResources, CachedAPIRequest} from "../../../core";
import {GatepassDataDTO, GatepassDTO,} from "../../feature/gatepass/interfaces/gatepass.entity";
import {handleError} from "../../../core/util/util";

@Injectable({
  providedIn: 'root'
})
export class GatepassService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<GatepassDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  $active = new BehaviorSubject<GatepassDataDTO | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.GatepassService);
  }

  create = (gatepass: any) => {
    return this.post<any>(gatepass, {}).pipe(
      tap(()=>{
        this.$all.next([])
      })
    );
  }

  getById = (id: string, refresh = true) => {
    return this.get<GatepassDataDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  update = (gatepassId: number, gatepassDetails: any) => {
    const options = {suffix: gatepassId.toString()};
    return this.patch<any>(gatepassDetails, options).pipe(
      tap(() => {
      })
    );
  }

  initial() {
    this.$active.next(undefined);
  }

  find(searchParams: any, refresh = true) {
    return this.get<GatepassDTO[]>({
      endpoint: `find`,
    }, refresh ? 'freshness' : 'performance')
        .pipe(
            tap(res=>this.$all.next(res.data??[])),
            catchError(handleError)
        )
  }
}
