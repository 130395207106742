import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {APIRequestResources, CachedAPIRequest, PaginationResponse} from "../../../../core";
import {BehaviorSubject, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {EmployeeDataDTO, EmployeeFindDTO,} from "../interface/employee.entity";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends CachedAPIRequest {

  $all = new BehaviorSubject<EmployeeFindDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<EmployeeDataDTO | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.EmployeeService);
  }

  find(searchParams: any, refresh = true) {
    return this.get<PaginationResponse<EmployeeFindDTO[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data.data);
        })
      );
  }

  getById = (id: string, refresh = true) => {
    return this.get<EmployeeDataDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  create = (employee: any) => {
    return this.post<any>(employee, {}).pipe();}

  update = (employeeId: number, employeeDetails: any) => {
    const options = {suffix: employeeId.toString()};
    return this.patch<any>(employeeDetails, options).pipe();}

  initial() {
    this.$active.next(undefined);}


}
