import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class';
import {APIRequestResources} from 'src/app/core/enums/api.request.enum';
import {take, tap} from 'rxjs';
import {toSignal} from "@angular/core/rxjs-interop";
import {EmployeeTypeDTO} from "../../interface/employee-type.entity";

@Injectable({
  providedIn: 'root'
})
export class EmployeeTypesService extends CachedAPIRequest {

  $all = new BehaviorSubject<EmployeeTypeDTO[]>([])
  all = toSignal(this.$all, {initialValue: []})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.EmployeeTypeService);
    this.find({}, true).pipe(take(1)).subscribe()
  }

  find(searchParams: any, refresh = false) {
    return this.get<EmployeeTypeDTO[]>({
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data);
        })
      );
  }


}
