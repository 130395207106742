import {Component, inject, signal,} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgClass, NgIf} from '@angular/common';
import {DotLoadingServices, NotificationService} from '../../../../core';
import {ManagerService} from '../../../services/manager.service';
import {ModalService} from "../../shared/services/modal.service";
import {EmployeeService} from "../../employee/services/employee.service";

@Component({
  selector: 'app-create-manager',
  standalone: true,
  imports: [FormsModule, NgIf, NgClass],
  templateUrl: './create-manager.component.html',
  styleUrls: ['./create-manager.component.scss'],
})
export class CreateManagerComponent {
  private notification = inject(NotificationService);
  private loading = inject(DotLoadingServices);

  managerId = signal(-1);
  managerService = inject(ManagerService);
  leaveService =inject(EmployeeService);
  modal = inject(ModalService)

  close(): void {
    this.modal.$$ManagerModal.set(false);
  }

}
