import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter} from "rxjs";
import {AuthService} from "../../../core";

@Component({
  selector: 'app-navigate',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './navigate.component.html',
  styleUrl: './navigate.component.scss'
})
export class NavigateComponent implements OnInit {
  currentRoute: string = '';

  constructor(private router: Router,public authService: AuthService) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = '/' + event.urlAfterRedirects.split('/')[1];
    });
  }

  isActive(route: string): boolean {
    return this.currentRoute.includes(route);
  }

  logout(){
    this.authService.signOut()
  }
}
