import {AfterViewInit, Component, effect, EventEmitter, inject, Input, OnDestroy, Output, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {DepartmentDTO} from "../../department/interface/department.entity";
import {CategoryDTO} from "../../category/interface/category.entity";
import {AuthService, DotLoadingServices, NotificationService} from "../../../../core";
import {EmployeeService} from "../services/employee.service";
import {DepartmentService} from "../../../shared/services/department.service";
import {CategoryService} from "../../../shared/services/category.service";
import {format, parseISO} from "date-fns";
import {Modal} from "flowbite";
import {catchError, EMPTY, finalize, Subject, take, takeUntil} from "rxjs";
import {EmployeeTypesService} from "../../../shared/services/employee-types.service";


@Component({
  selector: 'app-create-employee',
  standalone: true,
  imports: [
    FormsModule,
    NgClass
  ],
  templateUrl: './create-employee.component.html',
  styleUrl: './create-employee.component.scss'
})
export class CreateEmployeeComponent implements AfterViewInit, OnDestroy {
  @Input() showEmpModal = signal(false);
  @Output() showEmpModalChange = new EventEmitter<boolean>();

  employeeService = inject(EmployeeService)
  employeeTypesService = inject(EmployeeTypesService)
  departmentService = inject(DepartmentService)
  categoryService = inject(CategoryService)
  employeeType = inject(EmployeeTypesService)
  authService = inject(AuthService)
  notificationService = inject(NotificationService)
  dotLoadingServices = inject(DotLoadingServices)


  private modal: Modal | null = null;

  category: CategoryDTO[] = []
  department: DepartmentDTO[] = []
  private unsubscribe$ = new Subject<void>();
  today = format(new Date(), 'yyyy-MM-dd');
  protected readonly status = status;

  employeeId = signal(-1)
  userId = signal(this.authService.userID())

  searchParams = {
    code: '',
    status: '',
    category_name: '',
    employee_category_level: '',
    page_number: 1,
    items_per_page: 10
  }

  employeeCreateDTO = {
    code: '', fullName: '', displayName: '',
    nic: '', email: '', contactNumber: '',
    gender: '', status: '', joinDate: '', createdBy: 1,
    departmentId: -1, categoryId: -1, typeId: -1,
  };


  constructor() {
    effect(() => {
      const employee = this.employeeService.active()
      if (employee) {
        this.employeeId.set(employee.id),
          this.employeeCreateDTO = employee
      }
    }, {allowSignalWrites: true});
    effect(() => {
      if (this.showEmpModal()) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }, {allowSignalWrites: true});
  }
  private cleanUpdatePayload(payload: any): any {
    const { id, code,nic, ...cleanedPayload } = payload;
    return cleanedPayload;
  }

  submit() {
    this.dotLoadingServices.setLoading(true);
    const isUpdate = this.employeeId() > 0;
    const action = isUpdate ? 'update' : 'create';

    if (this.employeeCreateDTO.joinDate) {
      this.employeeCreateDTO.joinDate = format(parseISO(this.employeeCreateDTO.joinDate), 'yyyy-MM-dd');
    }

    let  payload = {...this.employeeCreateDTO,updatedBy:1};

    if (isUpdate){
      payload = this.cleanUpdatePayload(payload);
    }
    payload.departmentId =+payload.departmentId;
    payload.categoryId =+payload.categoryId;
    payload.typeId =+payload.typeId;


    console.log('final payload:', payload);

    const operation = isUpdate
      ? this.employeeService.update(this.employeeId(), payload)
      : this.employeeService.create(this.employeeCreateDTO);

    operation.pipe(
      takeUntil(this.unsubscribe$),
      finalize(() => this.dotLoadingServices.setLoading(false))
    ).subscribe({
      next: (response) => {
        this.notificationService.showNotification({
          type: 'success',
          message: `Employee ${action}d successfully`,
        });
        this.closeModal();
      },
      error: (error) => {
        this.notificationService.showNotification({
          type: 'error',
          message: 'errorMessage',
        });
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.initializeModal();
    this.employeeService.initial()
  }


  private initializeModal(): void {
    const modalElement = document.getElementById('crud-modal');
    if (modalElement) {
      this.modal = new Modal(modalElement, {
        placement: 'center',
        backdrop: 'static',
        closable: true,
        onHide: () => {
          this.showEmpModal.set(false);
          this.showEmpModalChange.emit(false);
        }
      });
    }
  }

  openModal(): void {
    this.modal?.show();
  }

  closeModal(): void {
    this.modal?.hide();
    this.employeeService.initial()
  }

  validateForm(): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const nicRegex = /^([0-9]{9}[vV]|[0-9]{12})$/;
    const phoneRegex = /^07[0-9]{8}$/;

    return !this.employeeCreateDTO.code ||
      !this.employeeCreateDTO.fullName ||
      !this.employeeCreateDTO.displayName ||
      !this.employeeCreateDTO.nic || !nicRegex.test(this.employeeCreateDTO.nic) ||
      !this.employeeCreateDTO.email || !emailRegex.test(this.employeeCreateDTO.email) ||
      !this.employeeCreateDTO.contactNumber || !phoneRegex.test(this.employeeCreateDTO.contactNumber) ||
      !this.employeeCreateDTO.gender ||
      !this.employeeCreateDTO.departmentId ||
      !this.employeeCreateDTO.categoryId ||
      !this.employeeCreateDTO.joinDate ||
      !this.employeeCreateDTO.status ||
      !this.employeeCreateDTO.typeId

  }

  toggleModal(show: boolean): void {
    this.showEmpModal.set(show);
    this.showEmpModalChange.emit(show);
  }

}
