@if (modal.$$ManagerModal()){
    <div id="crud-modal" tabindex="-1" aria-hidden="true"
         class="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50 ">
        <div class="relative p-4 w-3/4 max-w-6xl mx-auto bg-white rounded-lg shadow-lg max-h-[90vh] flex flex-col">
            <div class="relative flex-grow overflow-hidden">
                <div class="flex items-center justify-between p-4 border-b rounded-t ">
                    <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                        Leave Form
                    </h3>

                    <button type="button" (click)="close()"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                            data-modal-toggle="crud-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal </span>

                    </button>
                </div>
    <div class="lg:col-span-1 grid grid-cols-1 gap-4">
    <!-- Summary Cards -->
    <div class="bg-white shadow-md rounded-lg p-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <!-- Card 1 -->
            <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
                <div class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">3D
                </div>
                <div>
                    <p class="text-lg font-semibold">Day Leave</p>
                </div>
            </div>

            <!-- Card 2 -->
            <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
                <div class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">4D
                </div>
                <div>
                    <p class="text-lg font-semibold">Short Leave</p>
                </div>
            </div>
            </div>
        </div>
    </div>

    <!-- Table Section -->
    <div class="bg-white shadow-md rounded-lg p-4">
        <!-- Tabs -->
        <div class="flex gap-4 mb-4">
            <button class="px-4 py-2 text-base font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-800">Leave
            </button>
            <button class="px-4 py-2 text-base font-medium bg-gray-300 text-white rounded-lg hover:bg-blue-800">Gate Pass
            </button>
            <button class="px-4 py-2 text-base font-medium bg-gray-300 text-white rounded-lg hover:bg-blue-800 ml-auto">All
            </button>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto">
            <table class="Grid w-full">
                <thead>
                <tr class="border-b border-gray-200">
                    <th class="py-2 px-4">Leave Type</th>
                    <th class="py-2 px-4">Reason</th>
                    <th class="py-2 px-4">Date</th>
                    <th class="py-2 px-4">Shift</th>
                    <th class="py-2 px-4">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr class="border-b border-gray-200">
                    <td class="py-2 px-4">#20462</td>
                    <td class="py-2 px-4">Matt Dickerson</td>
                    <td class="py-2 px-4">13/05/2022</td>
                    <td class="py-2 px-4">Day</td>
                    <td>
                        <div class="px-8 p-2 text-base text-white bg-zinc-400 rounded-lg">Pending
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
            </div>
        </div>

}
