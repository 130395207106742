import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, catchError, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {APIRequestResources, CachedAPIRequest} from "../../../core";
import {GatepassTypeDTO} from "../../interface/gatepasstype.entity";

@Injectable({
  providedIn: 'root'
})
export class GatepasstypeService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<GatepassTypeDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  $active = new BehaviorSubject<GatepassTypeDTO | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.GatepasstypeService);
    this.find( true).pipe(take(1)).subscribe();
  }

  find( refresh = false) {
    return this.get<GatepassTypeDTO[]>({
      endpoint: `find`,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res=>this.$all.next(res.data)),
      )
  }
}
