import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {APIRequestResources, CachedAPIRequest, PaginationResponse} from "../../core";
import {BehaviorSubject, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {Manager, ManagerDetails, ManagerDTO} from "../feature/managarDashboard/interfaces/managar.entity";

@Injectable({
  providedIn: 'root'
})
export class ManagerService extends CachedAPIRequest {

  $all = new BehaviorSubject<ManagerDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<Manager | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.ManagerService);
    this.find({
      leave_type:'',
      employee_number:'',
      page_number:1,
      items_per_page:10,
    }, true).pipe(take(1)).subscribe();
  }

  create = (manager: any) => {
    return this.post<any>(manager, {}).pipe(
      tap(() => {
        this.$all.next([])
      })
    );
  }

  getById = (id: string, refresh= true) => {
    return this.get<Manager>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  update = (managerId: number, ManagerDetails: any) => {
    const options = {suffix: managerId.toString()};
    return this.put<any>(ManagerDetails, options).pipe(
      tap(() => {
      })
    );
  }

  initial() {
    this.$active.next(undefined);
  }

  find(searchParams: any, refresh = true) {
    return this.get<PaginationResponse<ManagerDetails[]>>({
      endpoint: `/find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data.data);
        })
      );
  }
}
