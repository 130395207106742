<nav class="bg-white shadow-md">
  <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 relative">
    <div class="flex justify-center items-center h-16">
      <div class="flex items-center space-x-4">
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/employee')"
          routerLink="/employee">Employee
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/category')"
          routerLink="/category">Category
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/department')"
          routerLink="/department">Department
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/leave')"
          routerLink="/leave">Leave
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/gatepass')"
          routerLink="/gatepass">gatepass
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/viewLeave')"
          routerLink="/viewLeave">LEAVE
        </button>
        <button
          class="nav-button"
          [class.active-nav-button]="isActive('/viewGatepass')"
          routerLink="/viewGatepass">GATEPASS
        </button>

      </div>
    </div>
    <div class="absolute right-4 top-1/2 transform -translate-y-1/2">
      <button type="button" class="button-blue" (click)="logout()">Exit</button>
    </div>
  </div>
</nav>
