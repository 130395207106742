<div class="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
  <div class="lg:col-span-2">

    <div class="p-6 bg-white shadow-md rounded-lg">
      <h1 class="font-bold text-3xl mb-4">Apply Gate Pass</h1>

      <form class="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label class="block text-sm font-medium mb-3">Employee Number</label>
          <input type="text" class="textField mb-4 w-full" placeholder="Type here"  name="firstName" [(ngModel)]="creategatepassDTO.employeeId"/>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Gate Pass Type</label>
          <select id="leave_type" class="textField mb-4 w-full"  name="type" required [(ngModel)]="creategatepassDTO.gatepassTypeId">
            <option value="">Select Gate Pass Type</option>

            @for(gatepass of gatepassTypeService.all(); track gatepass){
                  <option [value]="gatepass.gatePassTypeId">{{gatepass.gatePassType}}</option>
              }
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Select Reason</label>
          <select id="leave_reason" class="textField mb-4 w-full"  name="reason" required [(ngModel)]="creategatepassDTO.purpose">
            <option value="personal">Personal</option>
            <option value="official">Official</option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Date</label>
          <input type="date" class="textField mb-4 w-full"  name="date" [(ngModel)]="creategatepassDTO.requestedDate" />
        </div>

      </form>

      <div class="mt-6 flex gap-6">
        <button
          class="px-3 py-2 text-base text-center text-white bg-gray-400 rounded-lg hover:bg-gray-500
          focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium">Cancel</button>
        <button class="button-blue" (click)="createGatepass()">Apply Gatepass</button>
<!--                        [disabled]="validateForm()"-->
<!--                        [ngClass]="{'button-disabled': validateForm()}"-->

      </div>
    </div>
  </div>





  <!-- Right Section -->
  <div class="lg:col-span-1 grid grid-cols-1 gap-4">
    <div class="bg-white shadow-md rounded-lg p-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
          <div
            class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">3D
          </div>
          <div>
            <p class="text-lg font-semibold">Personal GatePass</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div class="bg-white shadow-md rounded-lg p-4">
      <!-- Tabs -->
      <div class="flex gap-4 mb-4">
        <button class="px-4 py-2 text-base font-medium bg-gray-300 text-white rounded-lg hover:bg-blue-800">pending
        </button>
        <button class="px-4 py-2 text-base font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-800">approve
        </button>
        <button class="px-4 py-2 text-base font-medium bg-blue-500 text-white rounded-lg hover:bg-blue-800">reject
        </button>
      </div>

      <!-- Table -->
      <div class="overflow-x-auto pb-8">
        <table class="Grid w-full">
          <thead>
          <tr class="border-b border-gray-200">
            <th class="py-2 px-4">employeeId</th>
            <th class="py-2 px-4">gatepassId</th>
            <th class="py-2 px-4">Purpose</th>
            <th class="py-2 px-4">Requested Date</th>
            <th class="py-2 px-4">Status</th>
          </tr>
          </thead>
          <tbody>
            @for(gatepass of gatepassService.all(); track gatepass){
              <tr class="border-b border-gray-200">
                <td class="py-2 px-4">{{gatepass.employeeId}}</td>
                <td class="py-2 px-4">{{gatepass.gatepassTypeId}}</td>
                <td class="py-2 px-4">{{ gatepass.purpose }}</td>
                <td class="py-2 px-4">{{ gatepass.requestedDate }}</td>
                <td class="py-2 px-4">{{gatepass.status}}</td>
              </tr>
            }
          <tr>
              <td>122</td>
              <td>344</td>
              <td>sgsrgd bdt</td>
              <td>13/574/366</td>
              <td ><button class="status-badge">122</button></td>
          </tr>
          </tbody>
        </table>
      </div >
    </div>
  </div>
</div>
