<!-- Report modal -->
<div id="crud-modal" tabindex="-1" aria-hidden="true"
     class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div>
    <div class="relative bg-white rounded-lg shadow">

      <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
          {{ employeeId() > 0 ? 'Update' : 'Create New' }} Employee
        </h3>
        <button type="button" (click)="toggleModal(false)"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-3">
          <!-- Full Name -->
          <div>
            <label for="Employee.name" class="block mb-2 text-sm font-medium text-gray-900">Full Name<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Employee.name" class="floating-btn peer" [(ngModel)]="employeeCreateDTO.fullName"
                     placeholder=""/>
            </div>
          </div>
          <!-- Display Name -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.displayName" class="block mb-2 text-sm font-medium text-gray-900">Display Name<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Employee.displayName" class="floating-btn peer"
                     [(ngModel)]="employeeCreateDTO.displayName" placeholder=" "/>
            </div>
          </div>
          <!-- Employee Code -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.Code" class="block mb-2 text-sm font-medium text-gray-900">Employee Code<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Employee.Code" class="floating-btn peer" [(ngModel)]="employeeCreateDTO.code"/>
            </div>
          </div>
          <!-- Employee Email -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.Email" class="block mb-2 text-sm font-medium text-gray-900">
              Employee Email</label>
            <div class="relative">
              <input type="text" id="Employee.Email" class="floating-btn peer" [(ngModel)]="employeeCreateDTO.email"
                     #emailInput="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
            </div>
            <div>
              @if (emailInput.errors?.['pattern']) {
                <div class="text-red-500 text-sm">Please enter a valid email address.</div>
              }
            </div>
          </div>
          <!-- Contact Number -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.contactNumber" class="block mb-2 text-sm font-medium text-gray-900">Contact Number<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="tel" id="Employee.contactNumber" name="contactNumber" class="floating-btn peer"
                     [(ngModel)]="employeeCreateDTO.contactNumber" placeholder=" " pattern="07[0-9]{8}" required #contactInput="ngModel"/>
            </div>
            <div>
              @if (contactInput.errors?.['pattern']) {
                <div class="text-red-500 text-sm">Please enter a valid 10-digit phone number.</div>
              }
            </div>
          </div>
          <!-- NIC -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.nic" class="block mb-2 text-sm font-medium text-gray-900">NIC<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="text" id="Employee.nic" name="nic" class="floating-btn peer" [(ngModel)]="employeeCreateDTO.nic"
                     placeholder=" " pattern="([0-9]{9}[vV]|[0-9]{12})"
                     title="Please enter a valid NIC. 10 digits followed by 'V' or 12 digits." required
                     #nicInput="ngModel"/>
            </div>
            @if (nicInput.errors?.['pattern']) {
              <div class="text-red-500 text-sm">Invalid NIC.</div>
            }
          </div>
          <!-- Gender -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.gender" class="block mb-2 text-sm font-medium text-gray-900">Gender<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <select id="Employee.gender" [(ngModel)]="employeeCreateDTO.gender"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
                <option hidden selected="">Choose a type</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
          </div>
          <!-- Employee Type -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.level" class="block mb-2 text-sm font-medium text-gray-900 ">Employee Type <span
              class="text-red-700">*</span></label>
            <select id="Employee.level" [(ngModel)]="employeeCreateDTO.typeId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
              @for (typ of employeeType.all(); track typ) {
                <option hidden value="">Choose a department</option>
                <option [value]="typ.id"> {{ typ.typeName }}</option>
              }
            </select>
          </div>
          <!-- Department -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.department" class="block mb-2 text-sm font-medium text-gray-900 ">Department <span
              class="text-red-700">*</span></label>
            <select id="Employee.department" [(ngModel)]="employeeCreateDTO.departmentId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
              @for (dep of departmentService.all(); track dep) {
                <option hidden value="">Choose a department</option>
                <option [value]="dep.id"> {{ dep.name }}</option>
              }
            </select>
          </div>
          <!-- Category -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.category" class="block mb-2 text-sm font-medium text-gray-900 ">Category<span
              class="text-red-700">*</span></label>
            <select id="Employee.category" [(ngModel)]="employeeCreateDTO.categoryId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
              @for (cate of categoryService.all(); track cate) {
                <option hidden value="">Choose a category</option>
                <option [value]="cate.id"> {{ cate.categoryName }}</option>
              }
            </select>
          </div>
          <!-- Employee Status -->
          <div class="col-span-2 sm:col-span-1">
            <label for="category.status" class="block mb-2 text-sm font-medium text-gray-900 ">Employee Status <span
              class="text-red-700">*</span></label>
            <select id="category.status" [(ngModel)]="employeeCreateDTO.status"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
              <option hidden selected disabled>Category Level</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="on-leave">On Leave</option>
            </select>
          </div>
          <!-- Join Date -->
          <div class="col-span-2 sm:col-span-1">
            <label for="Employee.joinDate" class="block mb-2 text-sm font-medium text-gray-900">Join Date<span
              class="text-red-700">*</span></label>
            <div class="relative">
              <input type="date" id="Employee.joinDate" [max]="today" class="floating-btn peer"
                     [(ngModel)]="employeeCreateDTO.joinDate" placeholder=" "/>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ">
        <button type="submit" [disabled]="validateForm()"
                [ngClass]="{'button-blue': !validateForm(), 'button-disabled': validateForm()}"
                (click)="submit()">
          {{ employeeId() > 0 ? 'Update' : 'Create New' }} Employee
        </button>
      </div>
    </div>
  </div>
</div>
