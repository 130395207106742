import {HttpClient,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, take, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {Category, CategoryDetail, CategoryDTO} from "../../feature/category/interface/category.entity";
import {APIRequestResources, CachedAPIRequest, PaginationResponse} from "../../../core";

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CachedAPIRequest {

  $all = new BehaviorSubject<CategoryDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<CategoryDTO | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.CategoryService);
    this.find({
      code: '',
      status:'',
      page_number:1,
      items_per_page:10,
    }, true).pipe(take(1)).subscribe();
  }

  create = (category: any) => {
    return this.post<any>(category, {}).pipe(
      tap(() => {
        this.$all.next([])
      })
    );
  }

  getById = (id: string, refresh= true) => {
    return this.get<Category>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  update = (categoryId: number, categoryDetails: any) => {
    const options = {suffix: categoryId.toString()};
    return this.patch<any>(categoryDetails, options).pipe(
      tap(() => {
      })
    );
  }

  initial() {
    this.$active.next(undefined);
  }

  find(searchParams: any, refresh = true) {
    return this.get<PaginationResponse<CategoryDetail[]>>({
      endpoint: `/find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => {
          this.$all.next(res.data.data);
        })
      );
  }
}
