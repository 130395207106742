import {AfterViewInit, Component, inject, signal} from '@angular/core';
import {CreateCategoryComponent} from "../../category";
import {PaginationComponent} from "../../../../core/components/pagination/pagination.component";
import {ManagerDTO} from "../interfaces/managar.entity";
import {ManagerService} from "../../../services/manager.service";
import {ModalService} from "../../shared/services/modal.service";
import {CreateManagerComponent} from "../create-manager/create-manager.component";
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {ArcElement, Chart, Legend, Tooltip,} from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

@Component({
  selector: 'app-manager-grid',
  standalone: true,
  imports: [
    CreateCategoryComponent,
    PaginationComponent,
    CreateManagerComponent,
    RouterLink,
    FormsModule
  ],
  templateUrl: './manager-grid.component.html',
  styleUrl: './manager-grid.component.scss',

})
export class ManagerGridComponent implements AfterViewInit{
  ngAfterViewInit() {
    this.createChart();
  }

  ngOnInit() {
    this.createChart();
  }

  totalItems = 0;
  itemsPerPage = 10;

  managerService = inject(ManagerService)
  modal = inject(ModalService)

  managerDTO: ManagerDTO[] = [];

  showCreateCategory = signal(false);

  onPageChange(pageNumber: number) {
  }

  onShowModalChange(show: boolean) {
    this.showCreateCategory.set(show);
  }

  openModal() {
    this.modal.$$ManagerModal.set(true)
  }

  public chart: any;

  createChart() {
    this.chart = new Chart('pieChart', {
      type: 'doughnut',
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple'],
        datasets: [{
          data: [30, 25, 15, 20, 10],
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF'
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF'
          ]
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Pie Chart Example'
          }
        }
      }
    });
  }
}


