<div class="grid grid-cols-1 lg:grid-cols-3 gap-6 p-6">
  <!-- Left Section -->
  <div class="lg:col-span-2">
    <!-- Nav Bar -->
<!--    <div-->
<!--      class="flex justify-start items-center p-4 bg-white shadow-md rounded-lg gap-8 mb-6"-->
<!--    >-->
<!--      <button-->
<!--        (click)="setActive('leave')"-->
<!--        [class.active]="isLeaveActive"-->
<!--        [class.inactive]="!isLeaveActive"-->
<!--      >-->
<!--        Leave-->
<!--      </button>-->
<!--      <button-->
<!--        (click)="setActive('gatepass')"-->
<!--        [class.active]="isGatePassActive"-->
<!--        [class.inactive]="!isGatePassActive"-->
<!--      >-->
<!--        Gate Pass-->
<!--      </button>-->
<!--    </div>-->

    <!-- Leave Form -->
    <div class="p-6 bg-white shadow-md rounded-lg">
      <h1 class="font-bold text-3xl mb-4">Apply Leave</h1>



      <!-- Form -->
      <form class="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label class="block text-sm font-medium mb-3">Employee Number</label>
          <input type="text" class="textField mb-4 w-full" placeholder="Type here"/>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Leave Type</label>
          <select id="leave_type" class="textField mb-4 w-full" required>
            <option value="">Select Leave Type</option>
            <option value="day">Day Leave</option>
            <option value="short">Short Leave</option>
            <option value="half_day">Half Day</option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Select Reason</label>
          <select id="leave_reason" class="textField mb-4 w-full" required>
            <option value="personal">Personal</option>
            <option value="official">Official</option>
          </select>
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Date</label>
          <input type="date" class="textField mb-4 w-full" />
        </div>

        <div>
          <label class="block text-sm font-medium mb-3">Attachment</label>
          <input type="text" class="textField mb-4 w-full" placeholder="Type here"
          />
        </div>
      </form>

      <!-- Action Buttons -->
      <div class="mt-6 flex gap-6">
        <button class="px-3 py-2 text-base text-center text-white bg-gray-400 rounded-lg hover:bg-gray-500
        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium">Cancel
        </button>
        <button class="button-blue">Apply Leave</button>
      </div>
    </div>
  </div>

  <!-- Right Section -->
  <div class="lg:col-span-1 grid grid-cols-1 gap-4">
    <!-- Summary Cards -->
    <div class="bg-white shadow-md rounded-lg p-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <!-- Card 1 -->
        <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
          <div class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">3D
          </div>
          <div>
            <p class="text-lg font-semibold">Day Leave</p>
          </div>
        </div>

        <!-- Card 2 -->
        <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
          <div class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">4D
          </div>
          <div>
            <p class="text-lg font-semibold">Short Leave</p>
          </div>
        </div>

        <!-- Card 3 -->
        <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
          <div
            class="text-1xl font-bold bg-white text-blackbg-white text-black rounded-full h-12 w-12 flex
             items-center justify-center mr-4">1D
          </div>
          <div>
            <p class="text-lg font-semibold">Half Day</p>
          </div>
        </div>

        <!-- Card 4 -->
        <div class="p-4 bg-gray-100 shadow-md rounded-lg flex items-center">
          <div
            class="text-1xl font-bold bg-white text-black rounded-full h-12 w-12 flex items-center justify-center mr-4">3H
          </div>
          <div>
            <p class="text-lg font-semibold">Leave</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div class="bg-white shadow-md rounded-lg p-4">


      <!-- Table -->
      <div class="overflow-x-auto">
        <table class="Grid w-full">
          <thead>
            <tr class="border-b border-gray-200">
              <th class="py-2 px-4">Leave Type</th>
              <th class="py-2 px-4">Reason</th>
              <th class="py-2 px-4">Date</th>
              <th class="py-2 px-4">Shift</th>
              <th class="py-2 px-4">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-gray-200">
              <td class="py-2 px-4">#20462</td>
              <td class="py-2 px-4">Matt Dickerson</td>
              <td class="py-2 px-4">13/05/2022</td>
              <td class="py-2 px-4">Day</td>
              <td>
                <div class="px-8 p-2 text-base text-white bg-zinc-400 rounded-lg">Pending
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
