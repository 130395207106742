import {Component} from '@angular/core';

@Component({
  selector: 'app-leave-grid',
  standalone: true,
  imports: [],
  templateUrl: './leave-grid.component.html',
  styleUrl: './leave-grid.component.scss'
})
export class LeaveGridComponent {

}
