<div class="main">
  <div class="overflow-x-auto">
    <div class="flex flex-wrap justify-between items-center pb-4 bg-white w-full">
      <div class="flex gap-4 mb-4">
        <button class="px-4 py-2 text-base text-white bg-blue-500 hover:bg-blue-800 rounded-lg font-medium">Pending</button>
        <button class="px-4 py-2 text-base text-white bg-gray-300 hover:bg-blue-800 rounded-lg font-medium">Approve</button>
        <button class="px-4 py-2 text-base text-white bg-gray-300 hover:bg-blue-800 rounded-lg font-medium">Reject</button>
      </div>

      <table class="table">
        <thead class="table-thead">
        <tr>
            <th scope="col" class="px-6 py-3">Employee No</th>
            <th scope="col" class="px-6 py-3">Leave Type</th>
            <th scope="col" class="px-6 py-3">Gate Pass Type</th>
            <th scope="col" class="px-6 py-3">Reason</th>
            <th scope="col" class="px-6 py-3">Date</th>
            <th scope="col" class="px-6 py-3">Time</th>
            <th scope="col" class="px-6 py-3">Time-out</th>
            <th scope="col" class="px-6 py-3">Time-in</th>
            <th scope="col" class="px-6 py-3">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="px-6 py-4">1</td>
          <td class="px-6 py-4">personal</td>
          <td class="px-6 py-4">personal</td>
          <td class="px-6 py-4">2025/08/11</td>
          <td class="px-6 py-4">11.00</td>
          <td class="px-6 py-4"><button (click)="this.openModal()">!</button></td>
        </tr>
          @for(manager of managerDTO;track manager.employeeId){
            <tr class="bg-white border-b">
              <td class="px-6 py-4">{{ manager.employeeId }}</td>
              <td class="px-6 py-4">{{ manager.leaveType }}</td>
                <td class="px-6 py-4">{{ manager.gatepassType }}</td>
                <td class="px-6 py-4">{{ manager.reason }}</td>
                <td class="px-6 py-4">{{ manager.date }}</td>
              <td class="px-6 py-4">{{ manager.time }}</td>
              <td class="px-6 py-4">{{ manager.timeOut }}</td>
              <td class="px-6 py-4">{{ manager.timeIn }}</td>
              <td class="px-6 py-4" >
                <svg >" width="20" height="20" class="cursor-pointer" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 14H10V10H9M10 6H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282
                                            17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626
                                            4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821
                                            5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                    stroke="#1C64F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class ="" (click)="ngAfterViewInit() "></div>
    <div class=" main max-w-full border-4 justify-items-start">
      <div class="chart-container ">
        <canvas id="pieChart"></canvas>
      </div>
    </div>
  </div>



    <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                    (pageChanged)="onPageChange($event)"></app-pagination>

    </div>


<app-create-manager></app-create-manager>
